:root {
  --masthead-background-color: #292929;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-family: system-ui, sans-serif;
  font-weight: 300;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1;
}

a {
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

h1,
h2,
h3 {
  line-height: 1.2;
  font-weight: 300;
}

address {
  font-style: normal;
}

.text-center {
  text-align: center;
}

.container {
  max-width: 900px;
  padding-right: 15px;
  padding-left: 15px;
  margin-left: auto;
  margin-right: auto;
}

.row {
  display: flex;
  flex: 1;
  margin-right: -15px;
  margin-left: -15px;
}

.column {
  flex: 1;
  margin-right: 15px;
  margin-left: 15px;
}

.header {
  padding-top: 40px;
  padding-bottom: 40px;

  & h1 {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 100;
  }
}

.footer {
  padding-top: 40px;
  padding-bottom: 40px;

  & .container {
    display: flex;
    justify-content: center;
  }
}

.footer-info {
  display: flex;
  color: #999;
}

.footer-contact {
  align-self: center;

  & p {
    margin-bottom: 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    & + p {
      margin-top: 6px;
    }
  }
}

.footer-copyright {
  margin-right: 25px;

  & a {
    display: block;
  }

  & svg {
    display: block;
    width: auto;
    height: 40px;
  }

  & p {
    margin-top: 8px;
    margin-bottom: 0;
  }
}

.masthead {
  position: relative;
  display: flex;
  align-items: center;
  flex: 1;
  background-image:
    radial-gradient(
      0,
      color(var(--masthead-background-color) alpha(25%)),
      color(var(--masthead-background-color) alpha(50%))
    ),
    url("../img/masthead.jpg")
  ;
  background-size: cover;
  background-position: center top;
}

.masthead-credit {
  position: absolute;
  bottom: 10px;
  width: 100%;
  color: rgba(255,255,255,.25);
  text-align: center;
  font-size: 12px;

  & a {
    color: rgba(255,255,255,.3);
  }
}

.masthead-tagline {
  position: relative;
  flex: 1;
  opacity: .95;
  color: #fff;
  font-size: 48px;
  font-weight: 100;
  text-align: center;
}
